<template>
  <div class="list-filter">
    <div class="bg-white">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-4">
            <b-form-group
              id="input-group-name"
              label="Название"
              label-for="input-name"
            >
              <b-form-input
                id="input-name"
                v-model.trim="f.name"
                placeholder="Название"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group
              id="input-group-marketing-name"
              label="Маркетинговое название"
              label-for="input-marketing-name"
            >
              <b-form-input
                id="input-marketing-name"
                v-model.trim="f.marketing_name"
                placeholder="Маркетинговое название"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
      </div>
      <div class="col-md-12 d-flex row-switches flex-wrap">
        <switch-checkbox v-model="f.is_using" label="Используется" />
        <switch-checkbox
          v-model="f.is_hide_in_mobile_app"
          label="Скрыть в мобильном приложении"
        />

        <switch-checkbox
          v-model="f.has_logo_white"
          label="Лого на белом фоне"
        />

        <switch-checkbox
          v-model="f.has_logo_black"
          label="Лого на черном фоне"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FiltersMixin from './FiltersMixin';
import SwitchCheckbox from '../SwitchCheckbox.vue';
export default {
  components: { SwitchCheckbox },
  mixins: [FiltersMixin],
  data() {
    return {
      f: {
        name: this.$route.query?.name,
        marketing_name: this.$route.query?.marketing_name,
        is_using: this.$route.query?.is_using === 'true' || false,
        is_hide_in_mobile_app:
          this.$route.query?.is_hide_in_mobile_app === 'true' || false,
        has_logo_white: this.$route.query?.has_logo_white === 'true' || false,
        has_logo_black: this.$route.query?.has_logo_black === 'true' || false,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.list-filter {
  margin-bottom: 25px;

  .row-switches {
    @media (max-width: 658px) {
      flex-direction: column;
    }
    .custom-switch:not(:first-child) {
      @media (min-width: 659px) {
        margin-left: 25px;
      }
    }
  }
}
</style>
