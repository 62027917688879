<template>
  <div class="auto-dealer-table">
    <v-data-table
      :headers="headers"
      :items="items"
      item-key="name"
      disable-pagination
      class="elevation-1 row-links"
      hide-default-footer
      :header-props="{ 'sort-icon': 'mdi-sort-variant' }"
    >
      <template #item.is_using="{ item }">
        <yes-no-badge :active="item.is_using" />
      </template>

      <template #item.is_hide_in_mobile_app="{ item }">
        <yes-no-badge :active="item.is_hide_in_mobile_app" />
      </template>

      <template #item.has_logo_white="{ item }">
        <yes-no-badge :active="item.has_logo_white" />
      </template>

      <template #item.has_logo_black="{ item }">
        <yes-no-badge :active="item.has_logo_black" />
      </template>

      <template #item.oid="{ item }">
        <router-link
          :to="{ name: urlName, params: { id: item.oid } }"
          title="Редактировать"
          event=""
          class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
          @click.native.prevent="clickRow(item.oid)"
        >
          <span class="svg-icon svg-icon-md svg-icon-primary">
            <inline-svg src="/media/svg/icons/General/Edit.svg"></inline-svg>
          </span>
        </router-link>
      </template>

      <template #no-data>
        <div>Не найдены записи</div>
      </template>

      <template #no-results>
        <div>Не найдены записи</div>
      </template>
    </v-data-table>

    <b-modal v-model="modalShow" scrollable size="xl" @ok="saveForm">
      <template #modal-title>
        <h5>Редактирование {{ editItem.name }}</h5>
      </template>

      <brand-form v-model="editItem" ref="brandForm" />

      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">
          Сохранить
        </b-button>
        <b-button size="sm" variant="danger" @click="cancel()">
          Отмена
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import YesNoBadge from '../YesNoBadge';
import { GET_ITEM } from '@/core/services/store/brands.module';
import BrandForm from '../forms/BrandForm.vue';
export default {
  components: { YesNoBadge, BrandForm },

  props: {
    items: {
      type: Array,
      default: () => [],
    },

    urlName: {
      type: String,
      deafult: () => null,
    },
  },

  data() {
    return {
      modalShow: false,
      editItem: null,
      headers: [
        { text: 'Название', value: 'name', sortable: true },
        {
          text: 'Маркетинговое название',
          value: 'marketing_name',
          sortable: true,
        },
        { text: 'Используется', value: 'is_using', sortable: true },
        {
          text: ' Скрыть в мобильном приложении',
          value: 'is_hide_in_mobile_app',
          sortable: true,
        },
        { text: 'Лого на белом фоне', value: 'has_logo_white', sortable: true },
        {
          text: ' Лого на черном фоне',
          value: 'has_logo_black',
          sortable: true,
        },

        { text: 'Действия', sortable: false, value: 'oid' },
      ],
    };
  },

  mounted() {
    this.$root.$on('bv::modal::hidden', () => {
      this.editItem = null;
    });
  },

  methods: {
    async clickRow(oid) {
      this.editItem = await this.$store.dispatch(GET_ITEM, oid);
      this.modalShow = true;
      return false;
    },

    saveForm() {
      this.$refs?.brandForm?.submit();
    },
  },
};
</script>

<style lang="scss"></style>
