<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <v-expansion-panels class="mb-4">
            <v-expansion-panel>
              <v-expansion-panel-header> Фильтры </v-expansion-panel-header>
              <v-expansion-panel-content>
                <brands-list-filter />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <custom-header title="Марки" />
          <brands-list :items="filterItems" url-name="brands-detail" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  SET_BREADCRUMB,
  SET_BREADCRUMB_SHOW_BACK,
} from '@/core/services/store/breadcrumbs.module';
import { GET_ITEMS } from '@/core/services/store/brands.module';
import { mapState } from 'vuex';
import BrandsList from '@/view/content/lists/BrandsList.vue';
import BrandsListFilter from '../../../content/filters/BrandsListFilter.vue';

export default {
  components: { BrandsList, BrandsListFilter },

  computed: {
    ...mapState({
      items: (state) => state.brands.items,
    }),
    filterItems() {
      const keys = Object.keys(this.$route.query);

      if (keys.length > 0) {
        const filteredArray = this.items
          .map((item) => {
            const newItem = {};
            keys.forEach((key) => {
              if (
                item[key]
                  .toString()
                  .toLowerCase()
                  .includes(this.$route.query[key].toString().toLowerCase())
              ) {
                newItem.oid = item.oid;
                newItem[key] = item[key];
              } else {
                newItem[key] = null;
              }
            });
            return newItem;
          })
          .filter((x) => Object.keys(x).length > 0)
          .filter((x) => {
            const exluded = [];

            Object.keys(x).forEach((key) => {
              exluded.push(x[key] === null);
            });
            return exluded.every((x) => x === false);
          });

        return this.items.filter((x) => {
          const oids = filteredArray.map((z) => z.oid);
          return oids.includes(x.oid);
        });
      }

      return this.items;
    },
  },

  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Марки' }]);
    this.$store.commit(SET_BREADCRUMB_SHOW_BACK, false);
    await this.$store.dispatch(GET_ITEMS, this.$route.query);
  },
};
</script>
